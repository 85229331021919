<template>
    <div>
        <customexport-form></customexport-form>
    </div>
</template>

<script>
import customexportForm from '@/components/pages/customExport/CreateForm'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        customexportForm,
    },

    mixins: [dirtyForm],
}
</script>
